import React from "react"

import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"

import BiContent from "../components/bi-content"
import Button from "../components/button"
import MainBanner from "../components/main-banner"
import ConceptSection from "../components/concept-section"

import MeiquerLogo from "../assets/logos/logo-meiquer.svg"
import MeiquerTVLogo from "../assets/logos/logo-meiquer-tv.svg"
import EspiralMeiquerLogo from "../assets/logos/logo-espiral-meiquer.svg"
import ExperienciaMeiquerLogo from "../assets/logos/logo-experiencia-meiquer.svg"
import PlaceholderImage from "../assets/placeholders/placeholder-01@2x.png"

const mainHeaderContent = (
  <div>
    <p
      style={{
        marginBottom: "1rem",
        color: "black",
        fontWeight: 500,
        fontSize: "1.3em",
      }}
    >
      Imagina. Crea. Comparte
    </p>
    <p>
      Acercamos el <span className="c-turquoise w-bold">juego</span> creativo a
      la <span className="w-bold">infancia y su comunidad</span>: con cursos en
      las vacaciones, con talleres el fin de semana, con misiones en línea o con
      actividades interactivas.
    </p>
  </div>
)

const mainHeaderTitle = (
  <h1 className="w-normal">
    <span className="w-bold">Experiencias de</span>{" "}
    <span className="c-turquoise w-bold">Juego</span>
  </h1>
)

const infoBannerContent = (
  <>
    <h2 className="w-normal">
      <span className="w-bold">Transformando</span>{" "}
      <span className="s-italic">comunidades</span>{" "}
      <span className="c-turquoise w-bold">jugando</span>
    </h2>
    <p>
      Cuando jugamos, <span className="w-bold">desarrollamos habilidades</span>{" "}
      como la creatividad, la solución de problemas, la colaboración, la empatía
      y el pensamiento crítico. <span className="w-bold">inspiran</span> a las
      niñas y niños a jugar, explorar, imaginar y aprender análoga y
      digitalmente.
    </p>
    <p>
      <span className="c-turquoise w-bold">Jugar</span> también nos permite
      vivir un
      <span className="w-bold">proceso de crecimiento</span> para entender
      nuestras emociones, para conocer al otro y a nosotros mismos.
    </p>
  </>
)

const services = [
  {
    top: (
      <img style={{ width: "6em" }} src={MeiquerTVLogo} alt="Meiquer TV Logo" />
    ),
    content: (
      <p>
        Canal de YouTube con videos y actividades para imaginar, crear y jugar
        en familia.
      </p>
    ),
    bottom: (
      <Button href="https://www.youtube.com/channel/UCUYXSMJa2nxApcfcO1GXJug">
        Explorar
      </Button>
    ),
  },
  {
    top: (
      <img
        style={{ width: "6em" }}
        src={EspiralMeiquerLogo}
        alt="Espiral Meiquer Logo"
      />
    ),
    content: (
      <p>
        Experiencia de aprendizaje en línea para desarrollar habilidades
        jugando.
      </p>
    ),
    bottom: (
      <Button href="https://estacion-meiquer.netlify.app/espiral-meiquer/">
        Probar
      </Button>
    ),
  },
  {
    top: (
      <img
        style={{ width: "6em" }}
        src={MeiquerLogo}
        alt="Estación Meiquer Logo"
      />
    ),
    content: (
      <p>
        Espacios comunitarios de juego libres y seguros para niñas y niños en
        Zapopan.
      </p>
    ),
    bottom: <Button disabled>Próximamente</Button>,
  },
  {
    top: (
      <img
        style={{ width: "6em" }}
        src={ExperienciaMeiquerLogo}
        alt="Experiencia Meiquer Logo"
      />
    ),
    content: (
      <p>
        Experiencias de juego creativo para pascua y verano en espacios seguros
        e innovadores.
      </p>
    ),
    bottom: <Button disabled>Próximamente</Button>,
  },
]

const servicesTitle = (
  <h2 className="w-normal">
    ¿Quieres <span className="c-turquoise w-bold">jugar</span> con{" "}
    <span className="w-bold">nosotros</span>?
  </h2>
)

const MamasPapasPage = props => {
  const data = useStaticQuery(graphql`
    query MamasPapasQuery {
      file(relativePath: { eq: "meiquer-curso-escuela-02.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={props.location}>
      <SEO title="Mamás y Papás" />
      <BiContent
        image={PlaceholderImage}
        content={mainHeaderContent}
        title={mainHeaderTitle}
      />
      <MainBanner
        image={data.file.childImageSharp.fluid}
        content={infoBannerContent}
        fromBottom={false}
      />
      <ConceptSection concepts={services} title={servicesTitle} />
    </Layout>
  )
}

export default MamasPapasPage
